import React from 'react';

import { DateTime } from 'luxon';

import { shallowCopy } from './helpers';
import * as session from './session';
import { getUserInfo, getJoinInfo, joinTeam, login } from './ajax';
import validation from './validation';
import { FormCheckbox } from './FormElements';
import Modal from './Modal';


import LoadingCenter from './LoadingCenter';

class Join extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'loading', // 'loading', 

      // logged-in', 'not-found', 'too-early', 'too-late', 'seats-taken', 'ok', 

      // 'login' - user created/enrolled, start login flow
      // 'login-submitting' - sending confirmation code
      // 'login-error' - wrong confirmatino code, plz try again

      form: {
        email: '',
        // name: '',
        nameFirst: '',
        nameLast: '',
        terms: false,
        marketing: false,

        confirmationCode: '',
      },

      validate: false,
      tree: null,

      modalIsOpenJoinedTooEarly: false, // modal for 'join too early'

    };
    this.merge = this.merge.bind(this);
    this.validate = this.validate.bind(this);
    this.submitJoin = this.submitJoin.bind(this);
    this.submitLogin = this.submitLogin.bind(this);

    // possible states:
    // 'loading' - loading user data
    // 'logged-in' - user is logged in (user needs to logout first to create new account)
    // 
    // endpoints:
    // POST /join (with name, email, terms, marketing)
    //  - response 1: email does not belong to company (please use only authorized email domains, not private email)
    //  - response 2: this company plan is over quota, please contact your team manager
    //  - response 3: (login email sent) please enter code to login / finish creating account

  }

  async componentDidMount() {
    try {
      console.log(`[Join] Running componentDidMount()...`);
      // fetch user info
      let user = await getUserInfo();
      // console.log(`[Join] Fetched user.`, { user });
  
      // fetch plan/company info
      let joinInfo = await getJoinInfo(window.magicLink);
      console.log(`[Join] Fetched Join info.`, { joinInfo });

      let isOpenTooEarly = this.state.isOpenTooEarly;
      if (joinInfo.result === 'too-early') {
        isOpenTooEarly = true;
      }

      this.setState({
        state: joinInfo.result,
        modalIsOpenJoinedTooEarly: joinInfo.result === 'too-early' ? true : false,
        plan: joinInfo.plan
      });

      if (user !== null) {
        console.log(`[Join] User already logged in, redirecting to courses...`);
        this.setState({ state: 'logged-in' });
        window.location.replace(`/courses`);
        return;
      }
    }
    catch(e) {
      console.error(`[Join] Error executing componentDidMount().`, e);
    }
  }


  merge(newData) {
    // console.log('[Profile] Running merge...', { newData });
    let form = shallowCopy(this.state.form, newData);
    this.setState({
      form,
      tree: this.validate(null, form),
    });
  }


  validate(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.form;

    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.validate) {
        // check whether attribute inside validation tree is valid, if not return 'error' CSS class
        if (this.state.tree && this.state.tree[attribute] && this.state.tree[attribute].length > 0) {
          return 'error';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        // name: validation.validateString(data.name) ? null : 'Name is required.',
        nameFirst: validation.validateString(data.nameFirst) ? null : 'Name is required.',
        nameLast: validation.validateString(data.nameLast) ? null : 'Name is required.',
        email: validation.validateEmail(data.email) ? null : 'Email is required.',
        emailDomain: validateEmailDomain(data.email, this.state.plan.company.emailSuffixes) ? null : 'Email domain not allowed.',
      };
      return tree;
    }
  }


  async submitJoin() {
    try {
      
      console.log('[Join] Saving...');
      let tree = this.validate(null, this.state.form);
      let isHealthy = validation.isTreeHealthy(tree);
      this.setState({
        tree: tree,
        validate: true,
      });

      // return; // debug

      if (isHealthy) {
        let data = {
          // name: this.state.form.name,
          nameFirst: this.state.form.nameFirst,
          nameLast: this.state.form.nameLast,
          email: this.state.form.email,
          terms: this.state.form.terms,
          marketing: this.state.form.marketing
        };
        console.log('[Join] We have a valid form.', data);
        this.setState({ state: 'joining' });

        let result = await joinTeam(window.magicLink, data);

        console.log('[Join] Executed joinTeam()', result);

        if (result.success === true) {
          // user created, move on to login flow
          this.setState({ state: 'login' });
          return;
        }

        // user/email already exists, redirect to login page
        if(result.message === 'user-exists') {
          console.log(`[Join] User already exists, redirecting to login page...`);
          this.setState({ state: 'user-exists' });
          window.location.replace(`/login?e=${encodeURIComponent(this.state.form.email)}`);
          // window.location.replace(`/login?e=${this.state.form.email}`);
          return;
        }

      } else {
        console.log('[Join] We have an invalid form.', this.state.form);
      }

    }
    catch(e) {
      console.error(`[Join] Error executing submitJoin().`, e);
    }
  }


  async submitLogin() {
    try {

      let loggy = await login(this.state.form.email, this.state.form.confirmationCode);
      console.log('[Login] Did we login?', loggy);
      if (loggy.success) {
        console.log('[Login] Successful login, we should redirect here...');

        // publish user change
        await session.setUser(loggy.user);

        // redirect to /courses
        location.replace('/courses');
      }
      else {
        // wrong code; display error message
        this.setState({
          state: 'login-error',
          message: loggy.message,
        });
      }

    }
    catch(e) {
      console.error(`[Join] Error executing submitLogin().`, e);
    }
  }


  render() {

    return (
      <div>

        {
          this.state.state === 'loading' &&
          <div>
            <LoadingCenter />
          </div>
        }

        {
          this.state.state === 'logged-in' && // DONE
          <div>
            <JoinComputer plan={this.state.plan} />
            <div className='joinLine'></div>
            <br />
            <div className='validationRow'>
              <img src='/static/images/icon-warning.svg' />
              You are already logged into Product Institute.
            </div>
            <DisabledForm form={this.state.form} />
          </div>
        }

        {
          this.state.state === 'not-found' && // DONE
          <SadCat>
            <h1>We can’t find your company.</h1>
            <br />
            <p>
              Double check the link with your company manager.
            </p>
            <p>
              If you’re having trouble, please contact us at <a href="mailto:info@productinstitute.com">info@productinstitute.com</a>
            </p>
            <br />
            <div>
              <a href="/">
                <button className="pi3button">Back to home page</button>
              </a>
            </div>
          </SadCat>
        }

        {
          this.state.state === 'too-late' && // DONE
          <SadCat>
            <h1>The company plan has expired.</h1>
            <br />
            <p>
              If you’re having trouble, please contact us at <a href="mailto:info@productinstitute.com">info@productinstitute.com</a>
            </p>
            <br />
            <div>
              <a href="/">
                <button className="pi3button">Back to home page</button>
              </a>
            </div>
          </SadCat>
        }

        {
          this.state.state === 'seats-taken' && // DONE
          <SadCat>
            <h1>All the company seats have been taken.</h1>
            <br />
            <p>
              If you think this is an error, please contact your company manager.
            </p>
            <p>
              If you’re having trouble, please contact us at <a href="mailto:info@productinstitute.com">info@productinstitute.com</a>
            </p>
            <br />
            <div>
              <a href="/">
                <button className="pi3button">Back to home page</button>
              </a>
            </div>
          </SadCat>
        }

        {
          this.state.state === 'user-exists' && // DONE
          <div className='pi3form'>
            <JoinComputer plan={this.state.plan} />
            <div className='joinLine'></div>
            <br />
            <div className='validationRow'>
              <img src='/static/images/icon-warning.svg' />
              User with this email already exists. Please <a href='/login'>login</a> instead.
            </div>
            <DisabledForm form={this.state.form} />
          </div>
        }

        {/* {
          this.state.state === 'too-early' && // TODO this needs a modal dialog 
          <div>
            <JoinComputer company={this.state.plan.company.name} />
            <div className='joinLine'></div>
            <br />
            <div className='validationRow'>
              <img src='/static/images/icon-warning.svg' />
              Sorry, the company plan has not started yet. Please try again on {DateTime.fromISO(this.state.plan.startsAt).toFormat('LLLL d yyyy')} or later.
            </div>
            <DisabledForm form={this.state.form} />
          </div>
        } */}

        {
          this.state.state === 'login' && // TODO check screen with melissa/nile
          <div className='pi3form'>
            <JoinComputer plan={this.state.plan} />

            <div className='joinLine'></div>
            <br />
            <p>
              Welcome to Product Institute, you now have access to your team's courses!
              <br />
              Please proceed to the <a href='/login'>log in</a> page to start exploring.
              <br />
              A one-time code will be sent to your email for access, no passwords are needed.
            </p>
            <br />
            <div className="formRow submitRow">
              <a href={`/login?e=${this.state.form.email}`}>
                <button className="pi3button stretch">Log in</button>
              </a>
            </div>
          </div>
        }


        {
          (
            this.state.state === 'too-early' ||
            this.state.state === 'ok'
          ) &&
          <div className='pi3form'>

            {this.state.state === 'too-early' &&
              <Modal
                isOpen={this.state.modalIsOpenJoinedTooEarly}
                isEasyDismiss={false}
                onClose={() => this.setState({ modalIsOpenJoinedTooEarly: false })}>
                <h1>You’re Early!</h1>
                <p>
                  Your training hasn’t started yet, but don’t worry. We are working on it.
                </p>
                <p>
                  You can join now, but keep in mind you won't have access to any courses until {DateTime.fromISO(this.state.plan.startsAt).toFormat('LLLL d yyyy')}.
                </p>
                <p>
                  Reach out to your company manager for more details.
                </p>
                <div className='pi3modalButtonRow'>
                  <div
                    className='pi3pillButton'
                    onClick={() => this.setState({ modalIsOpenJoinedTooEarly: false })}>
                    Continue
                  </div>
                </div>
              </Modal>
            }

            <JoinComputer plan={this.state.plan} />

            <h2>Create an account</h2>
            <div className='joinLine'></div>

            {
              this.state.validate && this.state.tree.emailDomain &&
              <div>
                <br />
                <div className='validationRow'>
                  <img src='/static/images/icon-warning.svg' />
                  {/* Sorry, the email must be a {this.state.plan.company.name} email address. */}
                  Please use your company email to register.
                </div>
              </div>
            }

            {/* <div className="formRow">
              <label htmlFor="input-message">Name</label>
              <input
                type="text"
                id='input-name'
                name='name'
                value={this.state.form.name}
                onChange={ev => this.merge({ name: ev.target.value })}
                className={` ${this.validate('name')}`} 
              />
            </div> */}

            <div className="formRow">
              <label htmlFor="input-message">First Name</label>
              <input
                type="text"
                id='input-name'
                name='name-first'
                value={this.state.form.nameFirst}
                onChange={ev => this.merge({ nameFirst: ev.target.value })}
                className={` ${this.validate('nameFirst')}`} 
              />
            </div>

            <div className="formRow">
              <label htmlFor="input-message">Last Name</label>
              <input
                type="text"
                id='input-name-last'
                name='name-last'
                value={this.state.form.nameLast}
                onChange={ev => this.merge({ nameLast: ev.target.value })}
                className={` ${this.validate('nameLast')}`} 
              />
            </div>

            <div className="formRow">
              <label htmlFor="input-message">Company Email Address</label>
              <input
                type="text"
                id='input-email'
                name='email'
                value={this.state.form.email}
                onChange={ev => this.merge({ email: ev.target.value })}
                className={` ${this.validate('email')} ${this.validate('emailDomain')}`} 
              />
            </div>

            {this.state.plan.isTermsRequired &&
              <div className="formRow">
                <FormCheckbox
                  isChecked={this.state.form.terms}
                  isDisabled={false}
                  label='I agree to the Terms and Conditions'
                  onChange={terms => this.merge({ terms })}
                />
              </div>
            }

            {this.state.plan.isMarketingChoiceRequired && 
              <div className="formRow">
                <FormCheckbox
                  isChecked={this.state.form.marketing}
                  isDisabled={false}
                  label='I agree to receive emails from Product Institute'
                  onChange={marketing => this.merge({ marketing })}
                />
              </div>
            }

            <br />

            <div className="submitRowCenter">

              {
                this.state.plan.isTermsRequired && this.state.form.terms &&
                <button className='pi3button' onClick={this.submitJoin}>Create Account</button>
              }

              {
                this.state.plan.isTermsRequired && !this.state.form.terms &&
                <button className='pi3button' disabled>Create Account</button>
              }

              {
                !this.state.plan.isTermsRequired &&
                <button className='pi3button' onClick={this.submitJoin}>Create Account</button>
              }

              <br />
              <p>Have an account? <a href='/login'>Login instead</a></p>
            </div>

          </div>
        }

      </div>
    );
 
  }
}



function validateEmailDomain(email, domains) {
  let isValidDomain = false;
  if (domains && domains.length > 0) {
    for (let domain of domains) {
      if(email.indexOf(`@${domain}`) > -1) {
        isValidDomain = true;
        break;
      }
    }
  }
  else {
    isValidDomain = true;
  }
  return isValidDomain;
}

function JoinComputer(props) {
  return (
    <div className='joinComputer'>
      {/* <img src='/static/images/kompjutor.jpg' /> */}
      {/* <h1>Welcome <span>{props.plan.company.name}</span> to Product Institute!</h1> */}
      <h1>Welcome {props.plan.company.name} to Product Institute!</h1>
      {props.plan && props.plan.logo &&
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0 20px 0' }}>
          <img src={props.plan.logo.url} style={{ width: '200px' }} alt={props.plan.name} />
        </div>
      }
    </div>
  );
}


function DisabledForm(form) {
  return (
    <div>
      <div className="formRow">
        <label htmlFor="input-message">Name</label>
        <input
          type="text"
          id='input-name'
          name='name'
          value={form.name}
          disabled
        />
      </div>
      <div className="formRow">
        <label htmlFor="input-message">Email Address</label>
        <input
          type="text"
          id='input-email'
          name='email'
          value={form.email}
          disabled
        />
      </div>
      <div className="formRow">
        <FormCheckbox
          isChecked={form.terms}
          isDisabled={true}
          label='I agree to the Terms and Conditions'
        />
      </div>
      <div className="formRow">
        <FormCheckbox
          isChecked={form.marketing}
          isDisabled={true}
          label='I agree to receive emails from Product Institute'
        />
      </div>
      <br />
      <div className="submitRowCenter">
        <button className='pi3button' disabled>Create Account</button>
        <br />
        <p>Have an account? <a href='/login'>Login instead</a></p>
      </div>
    </div>
  );
}


function SadCat(props) {
  return (
    <main className="pi3sadCat">
      <img src="/static/images/404.svg" alt="Sad cat" />
      <div>
        {props.children}
      </div>
    </main>
  );
}

// // <Modal isOpen={isOpen} isEasyDismiss={true} />
// class Modal extends React.Component { 
//   constructor(props) {
//     super(props);
//     this.state = {
//       // isOpen: this.props.isOpen, // unnecessary!
//     };
//     this.wrapperRef = React.createRef();
//     this.handleClickOutside = this.handleClickOutside.bind(this);

//   }
//   handleClickOutside(event) {
//     if (this.props.isOpen && this.props.isEasyDismiss) {
//       if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
//         console.log('[Modal] Handling clickOutside...');
//         this.props.onClose();
//       }
//     }
//   }
//   async componentDidMount() {
//     try {
//       document.addEventListener('mousedown', this.handleClickOutside);
//     }
//     catch(e) {
//       console.error('[Modal] Error in componentDidMount().', e);
//     }
//   }
//   componentWillUnmount() {
//     document.removeEventListener('mousedown', this.handleClickOutside);
//   }
//   render() {
//     if (!this.props.isOpen) {
//       return '';
//     }
//     return (
//       <div className='pi3modalContainer'>
//         <div className='pi3modal' ref={this.wrapperRef}>

//           {/* <div className='pi3modalClose'>
//             <img src='/static/images/icon-close-small.svg' />
//           </div> */}

//           {this.props.children}

//         </div>
//       </div>
//     );
//   }
// }



export default Join;
